import { template as template_eee0d7721e2b4250b156c06c32852028 } from "@ember/template-compiler";
const FKText = template_eee0d7721e2b4250b156c06c32852028(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
