import { template as template_ab4f1ed75555499da35990abc844f23c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_ab4f1ed75555499da35990abc844f23c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
