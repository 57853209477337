import { template as template_e66540b4c43040f2b928562bcf4513fb } from "@ember/template-compiler";
const FKLabel = template_e66540b4c43040f2b928562bcf4513fb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
