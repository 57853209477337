import { template as template_11ceec1346eb4a2bb1c0af6b0f461625 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_11ceec1346eb4a2bb1c0af6b0f461625(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
